// src/MyPage.js
import React from 'react';
import AccountActivated from './components/AccountActivated';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';

const MyPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/activated" element={<AccountActivated />} />
          {/* Add other routes here */}
        </Routes>
      </Router>
    </div>
  );
};

export default MyPage;
